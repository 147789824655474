import { CircularProgress } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetLeaderboard } from "../hooks/useLeaderboards";
import { Leaderboard as LeaderboardModel } from "../models/leaderboards";
import CoopLeaderboard from "./CoopLeaderboard";
import SoloLeaderboard from "./SoloLeaderboard";

export default function Leaderboard() {
  const [solo, setSolo] = React.useState(
    (localStorage.getItem("solo") || "true") === "true"
  );
  const [level, setLevel] = React.useState<LeaderboardModel | null>(null);
  const { leaderboardId } = useParams<{ leaderboardId: string }>();

  React.useEffect(() => {
    useGetLeaderboard(leaderboardId!)
      .then((data) => {
        setLevel(data);
        if (!data.can_solo) {
          setSolo(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [leaderboardId]);

  const setSoloAndSave = (solo: boolean) => {
    setSolo(solo);
    localStorage.setItem("solo", solo.toString());
  };

  if (level === null) {
    return <CircularProgress />;
  }
  const soloLeaderboard = (
    <SoloLeaderboard level={level} toCoop={() => setSoloAndSave(false)} />
  );
  const coopLeaderboard = (
    <CoopLeaderboard level={level} toSolo={() => setSoloAndSave(true)} />
  );

  // Force coop leaderboard if the level can't be soloed
  if (!level.can_solo) {
    return coopLeaderboard;
  } else if (solo) {
    return soloLeaderboard;
  } else {
    return coopLeaderboard;
  }
}
