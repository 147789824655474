import React, { createContext } from "react";

const LoginContext = createContext({
  open: false,
  setOpen: (value: boolean) => {},
});

const LoginProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <LoginContext.Provider value={{ open, setOpen }}>
      {children}
    </LoginContext.Provider>
  );
};

export { LoginContext, LoginProvider };
