import { Home } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Divider, Link } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { useSnackbar } from "notistack";
import * as React from "react";
import { drawerWidth } from "../constants";
import { DrawerContext } from "../contexts/DrawerContext";
import { LoginContext } from "../contexts/LoginContext";
import { UserContext } from "../contexts/UserContext";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

export default function Navbar() {
  const drawerContext = React.useContext(DrawerContext);
  const loginContext = React.useContext(LoginContext);
  const userContext = React.useContext(UserContext);
  const title = "Spec Ops Leaderboard";
  const { enqueueSnackbar } = useSnackbar();

  const logOutCallback = () => {
    localStorage.removeItem("token");
    userContext.setUser(null);
    enqueueSnackbar("Logged out", {
      variant: "success",
      autoHideDuration: 2000,
    });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={drawerContext.open}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={[
              {
                mr: 2,
              },
              drawerContext.open && { display: "none" },
            ]}
            onClick={() => drawerContext.setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ marginRight: "28px", bgcolor: "white" }}
          />
          <Link
            variant="h6"
            noWrap
            sx={{ flexGrow: 1, display: "flex" }}
            underline="none"
            color="inherit"
            href="/"
          >
            <Home sx={{ marginRight: 1, alignSelf: "center" }} />
            {title}
          </Link>
          {userContext.user === null ? (
            <Button color="inherit" onClick={() => loginContext.setOpen(true)}>
              Login
            </Button>
          ) : (
            <Button color="inherit" onClick={logOutCallback}>
              Logout: {userContext.user!.name}
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
