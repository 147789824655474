import { ButtonGroup } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { LoginContext } from "../../contexts/LoginContext";
import { UserContext } from "../../contexts/UserContext";
import { useGetUser, useLogin, useSignUp } from "../../hooks/useLogin";

export default function Login() {
  const loginContext = React.useContext(LoginContext);
  const userContext = React.useContext(UserContext);
  const [error, setError] = React.useState<string | null>(null);
  const [loggingIn, setLoginForm] = React.useState<boolean>(true);
  const [usernameError, setUsernameError] = React.useState<boolean>(false);
  const [passwordError, setPasswordError] = React.useState<boolean>(false);

  const toggleLoginForm = () => {
    setLoginForm(!loggingIn);
  };

  const verifyUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.validity.valid) {
      setUsernameError(false);
    } else {
      setUsernameError(true);
    }
  };

  const verifyPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.validity.valid) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };

  const verifyConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const password = (document.getElementById("password") as HTMLInputElement)
      .value;
    const confirmPassword = (
      document.getElementById("confirmPassword") as HTMLInputElement
    ).value;

    if (password !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      setError(null);
    }
  };

  const loginForm = (
    <React.Fragment>
      <Dialog
        open={loginContext.open}
        onClose={() => loginContext.setOpen(false)}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());

            useLogin(formJson.username, formJson.password)
              .then((response) => {
                localStorage.setItem("token", response.access_token);
                loginContext.setOpen(false);

                useGetUser()
                  .then((user) => {
                    userContext.setUser(user);
                  })
                  .catch((error) => {
                    setError(`An unexpected error occurred: ${error.message}`);
                  });
              })
              .catch((error) => {
                switch (error.request.status) {
                  case 401:
                    setError("Invalid username or password");
                    break;
                  default:
                    setError(`An unexpected error occurred: ${error.message}`);
                    break;
                }
              });
          },
        }}
      >
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your username and password to login
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="username"
            label="Username"
            type="username"
            fullWidth
            variant="standard"
            onChange={verifyUsername}
            error={usernameError}
            helperText={
              usernameError
                ? "Please enter your username (Letters, numbers, underscores)"
                : ""
            }
            slotProps={{ htmlInput: { pattern: "[a-zA-Z0-9_]{3,16}" } }}
          />
          <TextField
            required
            margin="dense"
            id="password"
            name="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        {error && (
          <DialogContentText color="error" align="center">
            {error}
          </DialogContentText>
        )}
        <DialogActions>
          <ButtonGroup variant="contained" color="primary">
            <Button variant="outlined" onClick={toggleLoginForm}>
              Sign Up
            </Button>
            <Button
              variant="contained"
              onClick={() => loginContext.setOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Log in
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  const createForm = (
    <React.Fragment>
      <Dialog
        open={loginContext.open}
        onClose={() => loginContext.setOpen(false)}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());

            useSignUp(formJson.username, formJson.password)
              .then((response) => {
                localStorage.setItem("token", response.access_token);
                loginContext.setOpen(false);

                useGetUser()
                  .then((user) => {
                    userContext.setUser(user);
                  })
                  .catch((error) => {
                    setError(`An unexpected error occurred: ${error.message}`);
                  });
              })
              .catch((error) => {
                switch (error.request.status) {
                  case 409:
                    setError("An account with that username already exists");
                    break;
                  default:
                    setError(`An unexpected error occurred: ${error.message}`);
                    break;
                }
              });
          },
        }}
      >
        <DialogTitle>Sign up</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your desired username and password to create an account
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="username"
            label="Username"
            type="username"
            fullWidth
            variant="standard"
            onChange={verifyUsername}
            error={usernameError}
            helperText={
              usernameError
                ? "Please enter your username (Letters, numbers, underscores, 3-16 characters)"
                : ""
            }
            slotProps={{ htmlInput: { pattern: "[a-zA-Z0-9_]{3,16}" } }}
          />
          <TextField
            required
            margin="dense"
            id="password"
            name="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={verifyPassword}
            error={passwordError}
            helperText={
              passwordError
                ? "Your password is not valid (Letters, numbers, symbols, 3-24 characters)"
                : ""
            }
            slotProps={{ htmlInput: { pattern: "[a-zA-Z0-9_!@#$%^&*]{3,24}" } }}
          />
          <TextField
            required
            margin="dense"
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={verifyConfirmPassword}
          />
        </DialogContent>
        {error && (
          <DialogContentText color="error" align="center">
            {error}
          </DialogContentText>
        )}
        <DialogActions>
          <ButtonGroup variant="contained" color="primary">
            <Button variant="outlined" onClick={toggleLoginForm}>
              Log in
            </Button>
            <Button
              variant="contained"
              onClick={() => loginContext.setOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Sign up
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  return loggingIn ? loginForm : createForm;
}
