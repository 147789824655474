import axios from "axios";
import { Run } from "../models/leaderboards";
import { makeAuthenticatedRequest } from "./utilities";

const API_URL = process.env.REACT_APP_API_URL;

export async function useGetRun(runId: string): Promise<Run> {
  const response = await axios.get(`${API_URL}/runs/${runId}`);

  return response.data;
}

export async function useDeleteRun(runId: string): Promise<void> {
  await makeAuthenticatedRequest(`${API_URL}/runs/${runId}`, "delete");
}

export async function useEditRun(
  runId: string,
  score: number,
  time: number,
  primary_video: string,
  secondary_video: string | null
): Promise<void> {
  await makeAuthenticatedRequest(
    `${API_URL}/runs/${runId}`,
    "put",
    {},
    {
      score,
      time,
      primary_video,
      secondary_video,
    }
  );
}
