import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { drawerWidth } from "../constants";
import { DrawerContext } from "../contexts/DrawerContext";
import { useGetLeaderboards } from "../hooks/useLeaderboards";
import { Category } from "../models/leaderboards";
import { DrawerHeader } from "../utils/DrawerHeader";

export default function LevelsSelector() {
  const theme = useTheme();
  const drawerContext = React.useContext(DrawerContext);
  const [levels, setLevels] = React.useState<Category[]>([]);

  React.useEffect(() => {
    useGetLeaderboards()
      .then((data) => {
        setLevels(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={drawerContext.open}
      >
        <DrawerHeader key="header-button">
          <IconButton onClick={() => drawerContext.setOpen(false)}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <List key="header-title">
          <ListItem key={"header"} disablePadding>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  justifyContent={"center"}
                  display={"flex"}
                >
                  Levels
                </Typography>
              }
            />
          </ListItem>
        </List>
        <Divider key="divider" />
        {levels.map((category) => (
          <>
            <List key={category.name}>
              {category.levels.map((level) => (
                <ListItem key={level.name} disablePadding>
                  <ListItemButton href={`/leaderboard/${level.id}`}>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={level.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider key={category.name + "divider"} />
          </>
        ))}
      </Drawer>
    </Box>
  );
}
