import axios from "axios";
import { Token } from "../models/authentiation";
import { Runner } from "../models/leaderboards";
import { makeAuthenticatedRequest } from "./utilities";

const API_URL = process.env.REACT_APP_API_URL;

export async function useLogin(
  username: string,
  password: string
): Promise<Token> {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);

  const response = await axios.post(`${API_URL}/users/token`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
}

export async function useSignUp(
  username: string,
  password: string
): Promise<Token> {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);

  const response = await axios.post(`${API_URL}/users/signup`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
}

export async function useGetUser(): Promise<Runner> {
  const response = await makeAuthenticatedRequest<Runner>(
    `${API_URL}/users/me`
  );

  return response;
}
