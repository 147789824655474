import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import Home from "./components/Home";
import Leaderboard from "./components/Leaderboard";
import Run from "./components/Run";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container!);
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "leaderboard/:leaderboardId",
        element: <Leaderboard />,
      },
      {
        path: "run/:runId",
        element: <Run />,
      },
    ],
  },
]);

root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
