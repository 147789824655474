import React, { createContext } from "react";

const DrawerContext = createContext({
  open: false,
  setOpen: (value: boolean) => {},
});

const DrawerProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <DrawerContext.Provider value={{ open, setOpen }}>
      {children}
    </DrawerContext.Provider>
  );
};

export { DrawerContext, DrawerProvider };
