export function formatTime(time: number): string {
  const minutes = Math.floor(time / 60);
  const secondsAndMilliseconds = (time % 60).toFixed(1);
  const [seconds, milliseconds] = secondsAndMilliseconds.split(".");

  return `${minutes}:${seconds.toString().padStart(2, "0")}${
    milliseconds !== "0" ? `.${milliseconds}` : ""
  }`;
}

export function toSeconds(
  minutes: number,
  seconds: number,
  milliseconds: number
): number {
  return minutes * 60 + seconds + milliseconds / 1000;
}
