import { SnackbarProvider } from "notistack";
import React from "react";
import { DrawerProvider } from "../contexts/DrawerContext";
import { LoginProvider } from "../contexts/LoginContext";
import { UserProvider } from "../contexts/UserContext";

function Providers({ children }: { children: React.ReactNode }) {
  return (
    <DrawerProvider>
      <UserProvider>
        <LoginProvider>
          <SnackbarProvider>{children}</SnackbarProvider>
        </LoginProvider>
      </UserProvider>
    </DrawerProvider>
  );
}

export default Providers;
