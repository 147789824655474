import { Delete, Edit } from "@mui/icons-material";
import { Button, ButtonGroup, Dialog, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import * as React from "react";
import { useParams } from "react-router-dom";
import { drawerWidth } from "../constants";
import { DrawerContext } from "../contexts/DrawerContext";
import { UserContext } from "../contexts/UserContext";
import { useGetLeaderboardScores } from "../hooks/useLeaderboards";
import { useDeleteRun, useGetRun } from "../hooks/useRun";
import { Leaderboard, Run, SoloRunRow } from "../models/leaderboards";
import { DrawerHeader } from "../utils/DrawerHeader";
import { formatTime } from "../utils/formatTime";
import isAdmin from "../utils/isAdmin";
import EditDialog from "./dialogs/EditRun";
import RunSubmission from "./dialogs/RunSubmission";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
      },
    },
  ],
}));

export default function SoloLeaderboard({
  toCoop,
  level,
}: {
  toCoop: () => void;
  level: Leaderboard;
}) {
  const drawerContext = React.useContext(DrawerContext);
  const userContext = React.useContext(UserContext);
  const { leaderboardId } = useParams<{ leaderboardId: string }>();
  const [scores, setScores] = React.useState<SoloRunRow[]>([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const [rowCount, setRowCount] = React.useState(0);
  const [submissionOpen, setSubmissionOpen] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [rowToDelete, setRowToDelete] = React.useState<GridRowId | null>(null);
  const [editOpen, setEditOpen] = React.useState<boolean>(false);
  const [runToEdit, setRunToEdit] = React.useState<Run | null>(null);

  const handleDelete = (id: GridRowId) => {
    useDeleteRun(id.toString())
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns: GridColDef<SoloRunRow>[] = [
    {
      field: "place",
      headerName: "Place",
      width: 90,
    },
    {
      field: "player",
      headerName: "Player",
      width: 150,
    },
    {
      field: "score",
      headerName: "Score",
      width: 150,
    },
    {
      field: "time",
      headerName: "Time",
      width: 150,
    },
    {
      field: "link",
      headerName: "Run Link",
      width: 160,
      renderCell: (params) => (
        <Button variant="contained" href={`/run/${params.row.id}`}>
          Run Link
        </Button>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      hideable: false,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={() => {
              setConfirmDelete(true);
              setRowToDelete(id);
            }}
            color="error"
          />,
          <GridActionsCellItem
            icon={<Edit />}
            label="edit"
            onClick={() => {
              useGetRun(id.toString())
                .then((data) => {
                  console.log("here");
                  setRunToEdit(data);
                  setEditOpen(true);
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
            color="primary"
          />,
        ];
      },
    },
  ];

  React.useEffect(() => {
    useGetLeaderboardScores(
      leaderboardId!,
      paginationModel.page + 1,
      paginationModel.pageSize,
      false
    )
      .then((data) => {
        setRowCount(data.total);

        setScores(
          data.data.map((run) => ({
            id: run.id,
            place: run.place,
            player: run.primary_runner.name,
            time: formatTime(run.time),
            score: run.score,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [paginationModel.page, paginationModel.pageSize, leaderboardId]);

  return (
    <Main open={drawerContext.open}>
      <EditDialog
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        run={runToEdit}
      />
      <Dialog open={confirmDelete}>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6" sx={{ marginBottom: 10 }}>
            Are you sure you want to delete this run?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <ButtonGroup variant="contained">
              <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
              <Button
                onClick={() => handleDelete(rowToDelete!)}
                color={"error"}
              >
                Delete
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Dialog>
      <RunSubmission
        open={submissionOpen}
        setOpen={setSubmissionOpen}
        leaderboardId={leaderboardId!}
      />
      <DrawerHeader />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <h1>{level.name} - Solo</h1>
        {userContext.user !== null ? (
          <ButtonGroup variant="contained">
            <Button onClick={toCoop}>View Coop</Button>
            <Button onClick={() => setSubmissionOpen(true)}>Submit Run</Button>
          </ButtonGroup>
        ) : (
          <Button variant="contained" onClick={toCoop}>
            View Coop
          </Button>
        )}
      </Box>
      <Box sx={{ height: 800, width: "100%" }}>
        <DataGrid
          rows={scores}
          columns={columns}
          rowCount={rowCount}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25]}
          disableRowSelectionOnClick
          columnVisibilityModel={{ actions: isAdmin() }}
        />
      </Box>
    </Main>
  );
}
