import axios from "axios";
import { Category, Leaderboard, Run } from "../models/leaderboards";
import { PaginatedResponse } from "../models/pagination";
import { makeAuthenticatedRequest, paginateRequest } from "./utilities";

const API_URL = process.env.REACT_APP_API_URL;

export async function useGetLeaderboards(): Promise<Category[]> {
  const response = await axios.get(`${API_URL}/leaderboards/`);

  // The leaderboards should never change so I don't mind hard coding things
  const categories: Category[] = [];

  categories.push(
    new Category("tier 1", response.data.slice(0, 4)),
    new Category("tier 2", response.data.slice(4, 8)),
    new Category("tier 3", response.data.slice(8, 12)),
    new Category("tier 4", response.data.slice(12, 16)),
    new Category("dlc", response.data.slice(16))
  );

  return categories;
}

export async function useGetLeaderboard(
  leaderboardId: string
): Promise<Leaderboard> {
  const response = await axios.get(`${API_URL}/leaderboards/${leaderboardId}`);

  return response.data;
}

export async function useGetLeaderboardScores(
  leaderboardId: string,
  page: number,
  limit: number,
  coop: boolean
): Promise<PaginatedResponse<Run>> {
  const response = await paginateRequest<Run>(
    `${API_URL}/leaderboards/${leaderboardId}/runs`,
    page,
    limit,
    { coop: coop.toString() }
  );

  return response;
}

export async function useSubmitRun(
  leaderboardId: string,
  score: number,
  time: number,
  primary_video: string,
  secondary_video: string | null,
  partner: string | null
): Promise<Run> {
  const response: Run = await makeAuthenticatedRequest(
    `${API_URL}/leaderboards/${leaderboardId}`,
    "post",
    {},
    {
      time,
      primary_video,
      secondary_video,
      score,
      secondary_runner: partner,
    }
  );

  return response;
}
