interface PydanticParseError {
  loc: string[];
  msg: string;
  type: string;
}

export default function parse422Error(details: PydanticParseError[]): string {
  return details
    .map((detail) => {
      const loc = detail.loc.join(".");
      const msg = detail.msg;
      return `${msg}: ${loc}`;
    })
    .join("\n");
}
