import axios from "axios";
import { PaginatedResponse } from "../models/pagination";

export async function paginateRequest<T>(
  url: string,
  page: number,
  limit: number,
  params: Record<string, string> = {}
): Promise<PaginatedResponse<T>> {
  params = {
    ...params,
    page: page.toString(),
    limit: limit.toString(),
  };
  const response = await axios.get(`${url}?${new URLSearchParams(params)}`);

  return response.data;
}

export async function makeAuthenticatedRequest<T>(
  url: string,
  method: string = "get",
  headers: object = {},
  body: object = {}
): Promise<T> {
  const token = localStorage.getItem("token");
  headers = {
    ...headers,
    Authorization: `Bearer ${token}`,
  };

  if (!token) {
    throw new Error("No token found");
  }

  const response = await axios.request({
    url,
    method,
    headers,
    data: body,
  });

  return response.data;
}
