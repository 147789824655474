import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";
import React from "react";
import { useSubmitRun } from "../../hooks/useLeaderboards";
import { toSeconds } from "../../utils/formatTime";

function RunSubmission({
  open,
  setOpen,
  leaderboardId,
  startCoop = false,
  disableSolo = false,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  leaderboardId: string;
  startCoop?: boolean;
  disableSolo?: boolean;
}) {
  const timeErrorMessage = "Please enter a time in the format MM:SS.S";
  const scoreErrorMessage = "Please enter a score in the format #####";
  const videoErrorMessage = "Please enter a valid video link";
  const timeMatch =
    /^(?<minutes>[0-5]?\d):(?<seconds>[0-5]\d)(\.(?<milliseconds>\d{1}))?$/;
  const scoreMatch = /^\d{4,5}$/;
  const linkMatch = /^https?:\/\/.*/;

  const [error, setError] = React.useState<string | null>(null);
  const [timeError, setTimeError] = React.useState(timeErrorMessage);
  const [scoreError, setScoreError] = React.useState(scoreErrorMessage);
  const [primaryVideoError, setPrimaryVideoError] =
    React.useState(videoErrorMessage);
  const [secondaryVideoError, setSecondaryVideoError] =
    React.useState(videoErrorMessage);
  const [coop, setCoop] = React.useState(startCoop);

  const verifyTime = (e: any) => {
    const time = e.target.value;
    const match = time.match(timeMatch);

    if (match) {
      e.target.setCustomValidity("");
      setTimeError("");
    } else {
      setTimeError(timeErrorMessage);
    }
  };

  const verifyScore = (e: any) => {
    const score = e.target.value;
    const match = score.match(scoreMatch);

    if (match) {
      e.target.setCustomValidity("");
      setScoreError("");
    } else {
      setScoreError(scoreErrorMessage);
    }
  };

  const verifyPrimaryVideo = (e: any) => {
    const video = e.target.value;
    const match = video.match(linkMatch);

    if (match) {
      setPrimaryVideoError("");
    } else {
      setPrimaryVideoError(videoErrorMessage);
    }
  };
  const verifySecondaryVideo = (e: any) => {
    const video = e.target.value;
    const match = video.match(linkMatch);

    if (match) {
      setSecondaryVideoError("");
    } else {
      setSecondaryVideoError(videoErrorMessage);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const match = formJson.time.match(timeMatch);
            const totalSeconds = toSeconds(
              parseInt(match.groups.minutes),
              parseInt(match.groups.seconds),
              (parseInt(match.groups.milliseconds) || 0) * 100
            );
            useSubmitRun(
              leaderboardId,
              formJson.score,
              totalSeconds,
              formJson.video,
              formJson.partner_vidoeo,
              formJson.partner
            )
              .then(() => {
                setOpen(false);
                window.location.reload();
              })
              .catch((error) => {
                console.log(error);
                setError(
                  error.response.data?.detail || "An unexpected error occurred"
                );
              });
          },
        }}
      >
        <DialogTitle>Submit Run</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter your run details</DialogContentText>
          <TextField
            autoFocus
            autoComplete="off"
            required
            error={scoreError !== ""}
            helperText={scoreError}
            margin="dense"
            name="score"
            label="Score"
            type="text"
            fullWidth
            variant="standard"
            onChange={verifyScore}
          />
          <TextField
            autoComplete="off"
            required
            error={timeError !== ""}
            helperText={timeError}
            margin="dense"
            name="time"
            label="Time"
            type="text"
            fullWidth
            variant="standard"
            onChange={verifyTime}
          />
          <TextField
            autoComplete="off"
            required
            error={primaryVideoError !== ""}
            helperText={primaryVideoError}
            margin="dense"
            name="video"
            label="Video"
            type="text"
            fullWidth
            variant="standard"
            onChange={verifyPrimaryVideo}
          />
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={coop}
                  onChange={(e) => setCoop(e.target.checked)}
                  disabled={disableSolo}
                />
              }
              label="Co-op"
            />
            {coop && (
              <>
                <TextField
                  required
                  margin="dense"
                  name="partner"
                  label="Partner"
                  type="text"
                  variant="outlined"
                />
                <TextField
                  autoComplete="off"
                  required
                  error={secondaryVideoError !== ""}
                  helperText={secondaryVideoError}
                  margin="dense"
                  name="partner_vidoeo"
                  label="Partner's video"
                  type="text"
                  variant="outlined"
                  onChange={verifySecondaryVideo}
                />
              </>
            )}
          </Box>
        </DialogContent>
        {error && (
          <DialogContentText color="error" align="center">
            {error}
          </DialogContentText>
        )}
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default RunSubmission;
