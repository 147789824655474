import React, { createContext } from "react";
import { useGetUser } from "../hooks/useLogin";
import { Runner } from "../models/leaderboards";

const UserContext = createContext<{
  user: Runner | null;
  setUser: (value: Runner | null) => void;
}>({
  user: null,
  setUser: (value: Runner | null) => {},
});

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<Runner | null>(null);

  React.useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      useGetUser()
        .then((user) => {
          setUser(user);
        })
        .catch((error) => {
          console.log(`An unexpected error occurred: ${error.message}`);
        });
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
