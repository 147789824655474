export class Runner {
  id: number;
  name: string;
  admin: boolean;

  constructor(id: number, name: string, admin: boolean) {
    this.id = id;
    this.name = name;
    this.admin = admin;
  }
}

export class Leaderboard {
  id: number;
  name: string;
  can_solo: boolean;

  constructor(id: number, name: string, can_solo: boolean) {
    console.log(can_solo);
    this.id = id;
    this.name = name;
    this.can_solo = can_solo;
  }
}

export class Category {
  name: string;
  levels: Leaderboard[];

  constructor(name: string, levels: Leaderboard[]) {
    this.name = name;
    this.levels = levels;
  }
}

export class Run {
  id: number;
  place: number;
  leaderboard_id: number;
  leaderboard: Leaderboard;
  score: number;
  time: number;
  date: string;
  obsolete: boolean;
  coop: boolean;
  primary_video: string | null;
  secondary_video: string | null;
  primary_runner: Runner;
  primary_runner_id: number;
  secondary_runner: Runner | null;
  secondary_runner_id: number | null;

  constructor(
    id: number,
    place: number,
    leaderboard_id: number,
    leaderboard: Leaderboard,
    score: number,
    time: number,
    date: string,
    obsolete: boolean,
    coop: boolean,
    primary_video: string | null,
    secondary_video: string | null,
    primary_runner: Runner,
    primary_runner_id: number,
    secondary_runner: Runner | null,
    secondary_runner_id: number | null
  ) {
    this.id = id;
    this.place = place;
    this.leaderboard_id = leaderboard_id;
    this.leaderboard = leaderboard;
    this.score = score;
    this.time = time;
    this.date = date;
    this.obsolete = obsolete;
    this.coop = coop;
    this.primary_video = primary_video;
    this.secondary_video = secondary_video;
    this.primary_runner = primary_runner;
    this.primary_runner_id = primary_runner_id;
    this.secondary_runner = secondary_runner;
    this.secondary_runner_id = secondary_runner_id;
  }
}

export class SoloRunRow {
  id: number;
  place: number;
  player: string;
  time: string;
  score: number;

  constructor(
    id: number,
    place: number,
    player: string,
    time: string,
    score: number
  ) {
    this.id = id;
    this.place = place;
    this.player = player;
    this.time = time;
    this.score = score;
  }
}

export class CoopRunRow {
  id: number;
  place: number;
  players: string;
  time: string;
  score: number;

  constructor(
    id: number,
    place: number,
    players: string,
    time: string,
    score: number
  ) {
    this.id = id;
    this.place = place;
    this.players = players;
    this.time = time;
    this.score = score;
  }
}
