import { Card, CardHeader, CardMedia } from "@mui/material";
import React from "react";

export default function YoutubeEmbed({
  video,
  title,
}: {
  video: string;
  title: string;
}) {
  const ytMatch = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
  const ytMatched = video.match(ytMatch);

  if (!ytMatched) {
    return (
      <a href={video} target="_blank" rel="noreferrer">
        Unknown Link provided as proof
      </a>
    );
  }

  return (
    <Card
      raised
      sx={{
        height: 600,
      }}
    >
      <CardHeader title={title} sx={{ height: 50 }} />
      <CardMedia
        component="iframe"
        src={`https://www.youtube.com/embed/${ytMatched[1]}`}
        title={title}
        sx={{
          height: 550,
        }}
        allowFullScreen
      />
    </Card>
  );
}
