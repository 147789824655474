import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  Link,
  List,
  ListItem,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import {
  useGetLeaderboards,
  useGetLeaderboardScores,
} from "../hooks/useLeaderboards";
import { Leaderboard, Run } from "../models/leaderboards";

class leaderboardRuns {
  leaderboard: Leaderboard;
  runs: Run[];

  constructor(leaderboard: Leaderboard, runs: Run[]) {
    this.leaderboard = leaderboard;
    this.runs = runs;
  }
}

function LeaderboardCard({
  level,
  coop,
}: {
  level: leaderboardRuns;
  coop: boolean;
}) {
  return (
    <Box sx={{ minWidth: 300 }}>
      <Card>
        <CardHeader
          title={
            <Link
              noWrap
              href={`/leaderboard/${level.leaderboard.id}`}
              color={"inherit"}
            >
              {level.leaderboard.name}
            </Link>
          }
          sx={{ textAlign: "center" }}
        />
        <Divider />
        <CardContent>
          <List>
            {level.runs.map((run, index) => (
              <ListItem key={run.id} sx={{ margin: 0, padding: 0 }}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ textAlign: "right", width: "22px" }}
                >
                  {`${index + 1})`}
                </Typography>
                <Link
                  key={run.id}
                  variant="body2"
                  color="inherit"
                  href={`/run/${run.id}`}
                  sx={{ left: "30px", position: "absolute" }}
                >
                  {coop
                    ? `${run.primary_runner.name} and ${run.secondary_runner?.name} - ${run.score}`
                    : `${run.primary_runner.name} - ${run.score}`}
                </Link>
                {run.primary_video && (
                  <IconButton
                    href={run.primary_video}
                    target={"_blank"}
                    rel="noreferrer"
                    sx={{
                      margin: 0,
                      padding: "2px",
                      right: 0,
                      position: "absolute",
                    }}
                  >
                    <YouTubeIcon color={"error"} />
                  </IconButton>
                )}
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
}

export default function Home() {
  const [levelScores, setLevelScores] = React.useState<leaderboardRuns[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [solo, setSolo] = React.useState(
    (localStorage.getItem("solo") || "true") === "true"
  );

  const toggleSolo = () => {
    setSolo(!solo);
    localStorage.setItem("solo", (!solo).toString());
  };

  React.useEffect(() => {
    useGetLeaderboards().then((leaderboards) => {
      const levels = leaderboards
        .map((leaderboard) => leaderboard.levels)
        .flat()
        .filter((leaderboard) => (solo && leaderboard.can_solo) || !solo);

      Promise.all(
        levels.map(async (level) => {
          const runs = await useGetLeaderboardScores(
            level.id.toString(),
            1,
            10,
            !solo
          );
          return new leaderboardRuns(level, runs.data);
        })
      ).then((runs) => {
        setLevelScores(
          runs.sort((a, b) => a.leaderboard.id - b.leaderboard.id)
        );
        setLoading(false);
      });
    });
  }, [solo]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <FormControlLabel
        control={<Switch checked={!solo} onChange={() => toggleSolo()} />}
        label="coop"
        sx={{ marginTop: 15, justifyContent: "center", display: "flex" }}
      />
      <Grid
        container
        spacing={5}
        marginLeft={10}
        marginRight={10}
        marginBottom={10}
        justifyContent={"space-between"}
      >
        {levelScores.map((level) => (
          <LeaderboardCard
            key={level.leaderboard.id}
            level={level}
            coop={!solo}
          />
        ))}
      </Grid>
    </>
  );
}
